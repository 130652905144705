$Color1:rgb(237,150,78); /*Orange*/
$Color2:rgb(231, 120, 40); /*Orange Light*/
$Color3:rgb(60,67,87); /*Navy Blue */
$Color4:rgb(30,37,57); /*Navy Blue Light*/
$Color5:rgb(240,240,240); /*white text color*/

$Color1Light:rgb(241, 162, 97);
$Color2Dark:rgb(248, 118, 25);
$Color3Light:rgb(68, 134, 209); 
$Color4Light:rgb(48, 108, 177); 
$Color5Light:rgb(51, 51, 51); 


$widthWide:1000px;
$widthNarrow:600px;
$maxAppWidth:950px;

$borderRadius:4px;

$bannerHeightWide:50px;
$bannerHeightMid:40px;
$bannerHeightNarrow:35px;